import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore/';
import { AngularFireAuth } from '@angular/fire/auth/';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage/';
import { MatStepper } from '@angular/material/stepper';
import { NgxFileDropEntry, FileSystemDirectoryEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { jmbgValidator } from '../app.component';
import { Buffer } from 'buffer'
import { MatDatepicker } from '@angular/material/datepicker';
import { TosDialogComponent } from '../tos-dialog/tos-dialog.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss']
})
export class RegisterDialogComponent implements OnInit {
  [x: string]: any;
  public files: NgxFileDropEntry[] = [];
  startDate = new Date(1990, 0, 1);
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  uploadedImageSrc: SafeResourceUrl[] = [];
  uploadedImageUnsafeBase64: string[] = [];
  showLoading: boolean;
  indeksFiles: File[] = [];

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firestore: AngularFirestore,
    private _formBuilder: FormBuilder,
    private auth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
  ) {

  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(type)
    console.log(event.value)
    console.log(event.value.toString())
  }
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: any) => {
          if (file.size / 1024 / 1024 < 5) {
            this.secondFormGroup.removeControl('x');
            var fr = new FileReader();
            fr.onload = () => {
              this.uploadedImageUnsafeBase64.push(fr.result.toString().replace(/.*,/, ""));
              this.uploadedImageSrc.push(this.sanitizer.bypassSecurityTrustResourceUrl(fr.result.toString()));
            };
            fr.readAsDataURL(file);
            this.indeksFiles.push(file);
          } else {
            this.openSnackBar("Max 5MB", "OK")
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      indeks: ['', Validators.compose([Validators.required, jmbgValidator(/^\d{13}$/)])]
    });
    this.secondFormGroup = this._formBuilder.group({
      x: ['', Validators.required]
    });
  }
  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }
  public openTosDialog() {

  }
  public async registerUser(name, lastname, email, phone, dateOfBirth: Moment, indeks, stepper: MatStepper) {
    var ref = this.dialog.open(TosDialogComponent, {
      // width: width,
      data: {
      },
      panelClass: 'submitDialogStyle'
    });

    ref.afterClosed().subscribe(async (code) => {
      if (code === 200) {
        this.showLoading = true;
        this.openSnackBar("Uslovi prihvaćeni", "OK")
        try {
          var pass = name + lastname + Math.random() + "";
          await this.auth.createUserWithEmailAndPassword(email, pass);
          var userCred = await this.auth.signInWithEmailAndPassword(email, pass);
          var user = userCred.user;
          user.updateProfile({
            displayName: name + " " + lastname
          })
          await this.auth.sendPasswordResetEmail(user.email);
          var i = 0;
          var indeksURLs = [];
          // for (let url of this.uploadedImageUnsafeBase64) {
          //   indeksURLs.push(await (await this.storage.ref(`users/${user.uid}/indeks${i}.png`).putString(url, 'base64')).ref.getDownloadURL());
          //   i++;
          // }
          for (let file of this.indeksFiles) {
            var metadata = {
              contentType: file.type
            };
            indeksURLs.push(await (await this.storage.ref(`users/${user.uid}/${file.name}`)
              .put(file, metadata)).ref.getDownloadURL());
          }
          await this.firestore.doc(`users/${user.uid}`).set({
            displayName: name + " " + lastname,
            email: email,
            indeks: indeks,
            dateOfBirth: new Date(dateOfBirth.toString()),
            phone: phone,
            group: "srednja",
            indeksURLs: indeksURLs
          })
          this.showLoading = false;
          stepper.next();
        } catch (err) {
          console.log(err);
          this.dialogRef.close(400)
        }
      }
      else this.openSnackBar("Morate prihvatiti uslove ", "OK")
    })
  }

  onNoClick(code): void {
    this.dialogRef.close(code);
  }
  openLoginDialog() {
    this.onNoClick(401);
    var width = '40%';
    // if (window.outerWidth < 992) width = "80%"
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: width,
      height: '90%',
      data: {},
      panelClass: 'submitDialogStyle'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 200) this.openSnackBar("Ulogovan!", "ok")
      else if (result === 400) this.openSnackBar("Greška pri logovanju. Neuspešno.", "ok")
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
