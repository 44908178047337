import { Component, HostListener, InjectionToken, OnInit, Directive, Output, ElementRef, EventEmitter, ViewChild, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { BuyDialogComponent } from './buy-dialog/buy-dialog.component';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { RegisterDialogComponent } from './register-dialog/register-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClipsComponent } from './clips/clips.component';
import { AngularFirestore } from '@angular/fire/firestore';
@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  constructor(private _elementRef: ElementRef) { }

  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);
    }
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  DOCUMENT: InjectionToken<Document>
  @ViewChild(ClipsComponent) clipsComponent: ClipsComponent;
  navbarOpen = false;
  title = 'mata';
  website = "upisifon";
  selectedUniversity = {} as any;
  selectedFaculty = {} as any;
  currentAbout = 'aboutService';
  expanded = false;
  clips = undefined;
  universities = [];
  constructor(private ngZone: NgZone, private dialog: MatDialog, private _snackBar: MatSnackBar, private firestore: AngularFirestore) {
    firestore.collection('matars_clips').get()
      .subscribe(clipsSnap => {
        this.ngZone.run(() => {
          this.clips = clipsSnap.docs.map(clip => clip.data());
          this.selectedFaculty = {
            name: "FON",
            selected: true,
            videoURL: "https://youtube.com/embed/yKSV0r1mNYI",
            clips: this.clips.filter(c => c.faculty == "FON")
          }
          this.universities.forEach(u => {
            u.faculties.forEach(f => {
              f.clips = this.clips.filter(c => c.faculty == f.name)
            })
          })
          console.log(this.universities)
        })
      })
    firestore.collection('matars_universities').get()
      .subscribe(universitiesSnap => {
        firestore.collection('matars_faculties').get()
          .subscribe(facultiesSnap => {
            const allFaculties = facultiesSnap.docs.map(fs => {
              let faculty = fs.data();
              faculty.id = fs.id;
              return faculty;
            });
            firestore.collection('matars_groups').get()
              .subscribe(groupsSnap => {
                const allGroups = groupsSnap.docs.map(gs => {
                  let group = gs.data();
                  group.id = gs.id;
                  return group;
                });
                this.universities = universitiesSnap.docs.map(us => {
                  let university = us.data();
                  university.id = us.id;
                  university.selected = false;
                  university.faculties = allFaculties.filter(f => f.universityID == us.id);
                  university.faculties.forEach(f => {
                    f.groups = allGroups.filter(g => g.facultyID == f.id)
                  })
                  return university;
                })
                console.log(this.universities)
                this.selectedUniversity = this.universities[0];
                this.selectedUniversity.selected = true;
                this.selectedFaculty = this.selectedUniversity.faculties[0];
                this.selectedFaculty.selected = true;
              })
          })
      })
  }
  closeNavbar() {
    alert("CLOSE")
    this.navbarOpen = false;
  }
  scrollTo() {
    this.clipsComponent.scrollTo();
  }
  ngOnInit() {
    // this.navbarOpen = (window.innerWidth < 1025) ? false : true;
    this.navbarOpen = true;
  }
  questionClick(question) {
    question.open = !question.open
  }
  handleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  selectFaculty(university, faculty) {
    // if (window.innerWidth < 1025) this.handleNavbar();

    console.log(this.clipsComponent)
    this.clipsComponent.selectFaculty(faculty);
    this.selectedFaculty = faculty;
    this.selectedFaculty.groups[0].open = true;
    university.faculties.forEach(f => f.selected = false)
    faculty.selected = !faculty.selected;
    console.log(faculty)
  }
  selectUniversity(university) {
    this.universities.forEach(f => f.selected = false)
    university.selected = !university.selected;
    this.selectedUniversity = university;
  }
  expandParagraph() {
    this.expanded = !this.expanded;
  }
  async openRegistrationDialog() {
    if (true) {
      var width = '40%';
      // if (window.outerWidth < 992) width = "80%"
      const dialogRef = this.dialog.open(RegisterDialogComponent, {
        width: width,
        height: '90%',
        data: {
        },
        panelClass: 'submitDialogStyle'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 200) this.openSnackBar("Registracija uspešna!", "ok")
        else if (result === 400) this.openSnackBar("Registracija neuspešna. Molimo vas da probate ponovo", "ok")
      });
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
export function jmbgValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = nameRe.test(control.value);
    return !forbidden ? { 'forbiddenName': { value: control.value } } : null;
  };
}
