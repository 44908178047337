<table>
    <tr>
        <td>
            <div>
                <img src="../assets/images/strelica.png" />
            </div>
        </td>
        <td>
            <p>
                neograničen pristup svim video materijalima (sve potrebne oblasti i veliki broj rešenih zadataka)
            </p>
        </td>
    </tr>
    <tr>
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                3 simulacije polaganja prijemnog ispita
            </p>
        </td>
    </tr>
    <tr>
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                evaluacija nakon polaganja probnih prijemnih ispita
            </p>
        </td>
    </tr>
    <tr>
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                savetovanje budućih brucoša i praćenje napretka tokom cele pripreme
            </p>
        </td>
    </tr>
    <tr *ngIf="website == 'matars' && selectedFaculty.name == 'FON'">
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                Letnja škola nakon položenog prijemnog ispita, čiji je cilj sticanje predznanja potrebnog za uspešno praćenje najtežih ispita prve godine studija (brucoši koji ne pohađaju našu pripremu za prijemni, mogu se upisati na Summer School po ceni od 11990rsd).
                Više o letnjoj školi za FON možete pronaći na stranici
                <a target="_blank" href="http://upisifon.rs">www.upisifon.rs</a>
            </p>
        </td>
    </tr>
    <tr *ngIf="website == 'upisifon'">
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                Summer School letnja škola za sve brucoše FON-a
            </p>
        </td>
    </tr>
</table>