import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth/';
import { PasswordResetDialogComponent } from '../password-reset-dialog/password-reset-dialog.component';
import { AngularFireStorage } from '@angular/fire/storage/';
import { AngularFirestore } from '@angular/fire/firestore/';
import { userInfo } from 'os';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  uploadedImageSrc: SafeResourceUrl;
  uploadedImageUnsafeBase64: string;
  showLoading = false;
  constructor(public dialogRef: MatDialogRef<LoginDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private func:AngularFireFunctions,
    private firestore: AngularFirestore) {

  }
  ngOnInit(): void {

  }
  openPasswordResetDialog() {
    this.onNoClick(401);
    var width = '40%';
    // if (window.outerWidth < 992) width = "80%"
    const dialogRef = this.dialog.open(PasswordResetDialogComponent, {
      width: width,
      height: '90%',
      data: {},
      panelClass: 'submitDialogStyle'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 200) this.openSnackBar("Poslat email!", "ok")
      else if(result === 400) this.openSnackBar("Greška pri resetu. Neuspešno.", "ok")
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  login(email, password) {
    this.auth.signInWithEmailAndPassword(email, password)
      .then((userInfo) => {
        alert("Molim te sačekaj. Uskoro ćeš biti prebačen na korisnički portal :)")
        this.func.httpsCallable('createCustomToken')({})
        .subscribe(customToken=>{
          // window.location.href=`https://binarybioskop.com/korisnik?customToken=${customToken}`
          // window.location.href=`http://localhost:4000/korisnik?customToken=${customToken}`;
        })
      })
      .catch((err) => {
        console.log(err);
        if(err.code=="auth/wrong-password")
        this.openSnackBar("Pogrešna lozinka", "ok")
        if(err.message=="The password is invalid or the user does not have a password.")
        this.openSnackBar("Pogrešna lozinka", "ok")
        if(err.message=="There is no user record corresponding to this identifier. The user may have been deleted.")
        this.openSnackBar("Nepoznata email adresa", "ok")
        // this.dialogRef.close(400)
      })
  }
  onNoClick(code): void {
    this.dialogRef.close(code);
  }

}
