<div class="row d-flex d-lg-none footer mt-5">
    <div class="col-6 d-flex justify-content-center align-items-center flex-column h-100">
        <span>kontakt telefon</span>
        <a href="tel:+066001100" class="footerPhone">
            <img height="10px" src="../assets/images/phone.png" /> +38166001100
        </a>
    </div>
    <div class="col-6 d-flex justify-content-center align-items-center flex-column h-100">
        <div class="row justify-content-center">
            <div class="">
                <span>Powered by</span><br />
                <span>Binary биоскоп</span>
            </div>
            <div class="col-3 p-0 d-flex align-items-center">
                <img src="../assets/images/logo krug.png" class="w-100" />
            </div>
        </div>
    </div>
</div>