<table>
    <tr>
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                3 simulacije polaganja prijemnog ispita
            </p>
        </td>
    </tr>
    <tr>
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                evaluacija nakon polaganja probnih prijemnih ispita
            </p>
        </td>
    </tr>
    <tr>
        <td>
            <img src="../assets/images/strelica.png" />
        </td>
        <td>
            <p>
                video materijali sa detaljnim objašnjenjem rešavanja zadataka sa probnih prijemnih ispita
            </p>
        </td>
    </tr>
</table>