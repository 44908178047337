<div class="w-100 text-center my-2">
    <i>Već imaš nalog?
    <span (click)="openLoginDialog()" style="color: #ffbdcf; cursor: pointer;"
      ><u>Uloguj se.</u></span
    ></i
  >
</div>
<mat-horizontal-stepper
  [linear]="true"
  #stepper
  style="height: 90%;"
  class="d-flex flex-column"
  #stepper
>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup" class="w-100 overflow-auto">
      <ng-template matStepLabel>Podaci</ng-template>
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Ime</mat-label>
          <input matInput formControlName="name" placeholder="" required />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Prezime</mat-label>
          <input matInput formControlName="lastname" placeholder="" required />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Email</mat-label>
          <input
            type="email"
            formControlName="email"
            matInput
            placeholder=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Telefon</mat-label>
          <input
            type="tel"
            formControlName="phone"
            matInput
            placeholder=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field
          appearance="legacy"
          class="w-100"
          (click)="picker1.open()"
        >
          <mat-label>Datum rodjenja*</mat-label>
          <input
            matInput
            (dateChange)="addEvent('change', $event)"
            [matDatepicker]="picker1"
            formControlName="dateOfBirth"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>JMBG</mat-label>
          <input
            type=""
            formControlName="indeks"
            matInput
            placeholder="npr. 1234567891011"
            required
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <span>
          <i>Upisivanjem JMBG broja se slažeš da ga možemo koristiti za
          identifikaciju i označavanje video materijala koji ce biti dostupni
          samo tebi</i></span>
</div>
</form>
<div>
    <button class="w-100" style="background-color: #ffbdcf; border-radius: 25px;" mat-button matStepperNext>
        Dalje
      </button>
</div>
</mat-step>
<mat-step [stepControl]="secondFormGroup" [optional]="false" class="h-100 d-flex flex-column justify-content-center align-items-center">
    <ng-template matStepLabel>Knjižica</ng-template>
    <div class="col-12 text-center h-100">
        <ngx-file-drop accept=".png, .jpg" dropZoneClassName="dropZone row" dropZoneLabel="Prevucite sliku indeksa ovde" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="row m-0 justify-content-center">
                    <!-- <i
              class="material-icons mb-3 d-none d-lg-block"
              style="font-size:4rem;color:#ffbdcf"
            >
              cloud_upload
            </i> -->
                    <i class="col-12 text-dark">Prevuci ili
              <a
                style="color: #ffbdcf; cursor: pointer;"
                (click)="openFileSelector()"
                ><u>odaberi</u></a
              >
              sliku prve i druge strane đačke knjižice</i
            >
            <i class="text-dark" style="font-size: 0.7rem;"
              >*(slika mora biti .png ili .jpg ekstenzije maksimalne veličine
              5MB)</i
            >
            Ukoliko nemaš sliku đačke knjižice, moguće je dostaviti slike obe
            strane lične karte
          </div>
        </ng-template>
      </ngx-file-drop>
      <!-- <div class="w-100"> -->
      <div class="row overflow-auto" style="height: 65%;">
        <div
          class="col-12 height-fit-content"
          *ngFor="let url of uploadedImageSrc"
        >
          <img
            [src]="url"
            class="img-fluid py-1"
            style="border-radius: 25px; max-height: 60%;"
          />
        </div>
      </div>
      <!-- </div> -->
    </div>
    <div>
      <button
        class="w-50"
        style="background-color: #ffbdcf; border-radius: 25px;"
        mat-button
        matStepperPrevious
      >
        Nazad
      </button>
      <button
        class="w-50"
        style="background-color: #ffbdcf; border-radius: 25px;"
        mat-button
        matStepperNext
      >
        Dalje
      </button>
    </div>
  </mat-step>
  <mat-step
    class="h-100 d-flex flex-column justify-content-center align-items-center"
  >
    <ng-template matStepLabel>Slanje</ng-template>
    <div class="col-12 text-center h-100">
      <table class="text-left" style="font-size: 0.8rem;">
        <tr>
          <td>
            Ime:
          </td>

          <td>
            {{ firstFormGroup.value.name }}
          </td>
        </tr>
        <tr>
          <td>
            Prezime:
          </td>

          <td>
            {{ firstFormGroup.value.lastname }}
          </td>
        </tr>
        <tr>
          <td>
            Email:
          </td>

          <td>
            {{ firstFormGroup.value.email }}
          </td>
        </tr>
        <tr>
          <td>
            Telefon:
          </td>

          <td>
            {{ firstFormGroup.value.phone }}
          </td>
        </tr>
        <tr>
          <td>
            JMBG:
          </td>

          <td>
            {{ firstFormGroup.value.indeks }}
          </td>
        </tr>
      </table>
      <div class="row overflow-auto" style="height: 65%;">
        <div
          class="col-12 height-fit-content"
          *ngFor="let url of uploadedImageSrc"
        >
          <img
            [src]="url"
            class="img-fluid py-1"
            style="border-radius: 25px; max-height: 60%;"
          />
        </div>
      </div>
    </div>
    <div class="row m-0">
      <button
        class="w-50"
        style="background-color: #ffbdcf; border-radius: 25px;"
        mat-button
        matStepperPrevious
      >
        Nazad
      </button>
      <button
        class="w-50 d-flex justify-content-center align-items-center"
        style="background-color: #ffbdcf; border-radius: 25px;"
        mat-button
        (click)="
          registerUser(
            firstFormGroup.value.name,
            firstFormGroup.value.lastname,
            firstFormGroup.value.email,
            firstFormGroup.value.phone,
            firstFormGroup.value.dateOfBirth,
            firstFormGroup.value.indeks,
            stepper
          )
        "
      >
        <span><b>Pošalji</b></span>
        <i class="material-icons ml-1" style="font-size: 1rem;">
          send
        </i>
                    </button>
                </div>
                <div *ngIf="showLoading" class="position-absolute ng-star-inserted w-100 h-100 d-flex justify-content-center align-items-center" style="background-color: #f1f3f6;">
                    <img src="assets/images/loading.svg" class="img-fluid w-100" />
                </div>
</mat-step>
<mat-step class="h-100 d-flex flex-column justify-content-center align-items-center">
    <ng-template matStepLabel>Potvrda</ng-template>
    <div class="col-12 text-center h-100">
        <h3>
            {{ firstFormGroup.value.name }}, poslali smo ti e-mail koji sadrži link za aktivaciju naloga na {{ firstFormGroup.value.email }}
        </h3>
        <p>
            Ukoliko ti je potrebna pomoć, kontaktiraj nas na broj telefona 066/001100
        </p>
    </div>
    <div class="row m-0">
        <button class="w-100" style="background-color: #ffbdcf; border-radius: 25px;" mat-button (click)="onNoClick(200)">
        Zatvori
      </button>
    </div>
</mat-step>
</mat-horizontal-stepper>