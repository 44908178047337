import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-whats-included',
  templateUrl: './whats-included.component.html',
  styleUrls: ['./whats-included.component.scss']
})
export class WhatsIncludedComponent implements OnInit {

  @Input('website') website;
  @Input('selectedFaculty') selectedFaculty;
  
  constructor() { }

  ngOnInit(): void {
  }

}
