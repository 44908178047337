
import * as Sentry from '@sentry/angular';
import { Integrations } from "@sentry/tracing";

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { NgxFileDropModule } from 'ngx-file-drop'
import { SafePipeModule } from 'safe-pipe'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuyDialogComponent } from './buy-dialog/buy-dialog.component';
import { RegisterDialogComponent } from './register-dialog/register-dialog.component';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule, MatRadioGroup } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule, MatDatepicker } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { TosDialogComponent } from './tos-dialog/tos-dialog.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { PasswordResetDialogComponent } from './password-reset-dialog/password-reset-dialog.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { ClipsComponent } from './clips/clips.component';
import { PricingTableComponent } from './pricing-table/pricing-table.component';
import { WhatsIncludedComponent } from './whats-included/whats-included.component';
import { WhatsIncludedTrialComponent } from './whats-included-trial/whats-included-trial.component';
import { FooterComponent } from './footer/footer.component';
import { Router } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'DD. MMM, YYYY',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const config = {
  apiKey: "AIzaSyBe7FZhZSiogrSCv1BdC61gmYAe2JGajYA",
  authDomain: "binarybioskop-1551559206895.firebaseapp.com",
  databaseURL: "https://binarybioskop-1551559206895.firebaseio.com",
  projectId: "binarybioskop-1551559206895",
  storageBucket: "binarybioskop-1551559206895.appspot.com",
  messagingSenderId: "132387797455",
  appId: "1:132387797455:web:8870185f37c2b7bd093aba",
  measurementId: "G-X55KH54GDF"
};

Sentry.init({
  dsn: 'https://a3d821cf189c43148b37e62a589433f0@o445446.ingest.sentry.io/5421856',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://yourserver.io/api"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});
@NgModule({
  declarations: [
    AppComponent,
    BuyDialogComponent,
    RegisterDialogComponent,
    TosDialogComponent,
    LoginDialogComponent,
    PasswordResetDialogComponent,
    ClipsComponent,
    PricingTableComponent,
    WhatsIncludedComponent,
    WhatsIncludedTrialComponent,
    FooterComponent,
    AboutUsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SafePipeModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    FormsModule, ReactiveFormsModule,
    NgxFileDropModule,
    MatStepperModule,
    MatChipsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,

    AngularFireModule.initializeApp(config),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
  ],
  entryComponents: [
    BuyDialogComponent,
    RegisterDialogComponent,
    TosDialogComponent,
    MatDatepicker,
    LoginDialogComponent,
    PasswordResetDialogComponent
  ],
  providers: [
    // { provide: ORIGIN, useValue: 'http://localhost:5000' },
    // { provide: REGION, useValue: 'us-central1' }
    { provide: MAT_DIALOG_DATA, useValue: {} }, {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }, { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
