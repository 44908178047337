<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-checkbox
      #theft
      (change)="checked(theft, 'theft')"
        >Potvrđujem da ću sav sadržaj, za koji mi bude odobren pristup od strane
        Edukativnog centra Binary Bioskop, koristiti isključivo u svrhu lične
        edukacije i preuzimam punu krivičnu odgovornost u skladu sa Zakonom
        Republike Srbije kojom se obavezujem da pomenuti materijal ni na koji
        način neću preuzimati ili distribuirati.</mat-checkbox
      >
    </div>
    <div class="col-12">
      <mat-checkbox
      #terms
      (change)="checked(terms, 'terms')"
        >Saglasan sam sa
        <a target="_blank" href="uslovi-koriscenja"
          >Uslovima korišćenja sajta </a
        >www.binarybioskop.com</mat-checkbox
      >
    </div>
  </div>
</div>
