<div class="container-fluid h-100 position-relative" [ngClass]="{ 'overflow-hidden': showLoading || showThankYou }">
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Izaberi Univerzitet:</mat-label>
                <mat-select [(value)]="data.selectedUniversity">
                    <mat-option *ngFor="let uni of data.universities" [value]="uni">{{ uni.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Izaberi Fakultet:</mat-label>
                <mat-select [(value)]="selectedFaculty">
                    <mat-option *ngFor="let fac of data.selectedUniversity.faculties" [value]="fac">{{ fac.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <hr class="w-100">
        <div class="col-12">
            <mat-radio-group (change)="fullnesChange($event)" [value]="fullnes">
                <mat-radio-button value="full" class="col-12 col-lg-6">Cela priprema</mat-radio-button>
                <mat-radio-button value="part" class="col-12 col-lg-6">Paket probni prijemni</mat-radio-button>
            </mat-radio-group>
        </div>
        <hr class="w-100">
        <div class="col-12">
            <mat-radio-group (change)="applicationTypeChange($event)" [value]="applicationType">
                <mat-radio-button value="individual" class="col-12 col-lg-6">Individualna prijava (24000 rsd)</mat-radio-button>
                <mat-radio-button value="group" class="col-12 col-lg-6">Grupna prijava({{groupPrice}} rsd)</mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="applicationType == 'group'" class="col-4 col-lg-6">
            <mat-form-field (change)="numOfMembersChange(numOfMembers.value)" appearance="legacy" class="w-100">
                <mat-label>Broj članova</mat-label>
                <input matInput type="number" #numOfMembers [(value)]=members.length min=3 placeholder="Broj članova" />
            </mat-form-field>
        </div>
        <div *ngIf="applicationType == 'group'" class="col-8 col-lg-6 d-flex justify-content-start align-items-center">
            <button mat-stroked-button class="" (click)="incrementNumOfMembers()">
                <span class="material-icons">add</span>
            </button>
            <button mat-stroked-button class="ml-3" (click)="decrementNumOfMembers()">
                <span class="material-icons">remove</span>
            </button>
        </div>
        <div *ngFor="let member of members" class="w-100">
            <div *ngIf="numOfMembers > 2" class="col-12">
                <h3 class="m-0">{{ member.index + 1 }}. član</h3>
            </div>
            <div class="col-12">
                <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>Ime i prezime</mat-label>
                    <input (change)="nameChange($event, member)" matInput placeholder="Ime i prezime" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>Email</mat-label>
                    <input (change)="emailChange($event, member)" matInput placeholder="Email" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>Broj telefona</mat-label>
                    <input (change)="phoneChange($event, member)" type="number" matInput placeholder="Broj telefona" />
                </mat-form-field>
            </div>
        </div>
        <div class="col-12">
            <mat-checkbox (change)="hasPromoCodeChange()" class="example-margin w-100">Imam promo kod</mat-checkbox>
        </div>
        <div *ngIf="hasPromoCode" class="col-12">
            <mat-form-field appearance="legacy">
                <mat-label>Promo kod</mat-label>
                <input (change)="promoCodeChange(promoCode.value)" #promoCode matInput placeholder="Promo kod" />
            </mat-form-field>
        </div>
        <div class="col-12 py-1 text-center">
            <button mat-raised-button color="accent" (click)="sendApplication()"> 
                Prijavi se
            </button>
        </div>
        <p style="text-align: center;"><i>Nakon prijave će vas kontaktirati neko iz BinaryBioskop tima</i></p>
    </div>
    <div class="h-100 loading align-items-center d-none" [ngClass]="{ 'd-flex': showLoading }">
        <img class=" w-100" src="../../assets/images/loading.svg" />
    </div>
    <div [ngClass]="{ 'd-flex': showThankYou }" class="thankyou align-items-center text-center d-none">
        <div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h1>Hvala na ukazanom poverenju.</h1>
                    <h3>Kontaktiraćemo vas radi daljih uputstava.</h3>
                    <h3>Vaš Binary Bioskop</h3>
                </div>
                <div class="col-12 col-lg-6">
                    <img class="w-100" src="../../assets/images/logo krug.png" />
                </div>
            </div>
        </div>
    </div>
</div>