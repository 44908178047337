import { Component, OnInit, Inject } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore/';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireStorage } from '@angular/fire/storage/';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.scss']
})
export class PasswordResetDialogComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  uploadedImageSrc: SafeResourceUrl;
  uploadedImageUnsafeBase64: string;
  showLoading = false;
  constructor(public dialogRef: MatDialogRef<LoginDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firestore: AngularFirestore,
    private _formBuilder: FormBuilder,
    private auth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private storage: AngularFireStorage) {
  }

  ngOnInit(): void {
  }
  resetPassword(email) {
    this.auth.sendPasswordResetEmail(email)
      .then((data) => {
        this.dialogRef.close(200)
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 'auth/invalid-email')
          this.openSnackBar("Nepoznata email adresa", "ok")
        if (err.code === 'auth/user-not-found')
          this.openSnackBar("Nepoznata email adresa", "ok")
      })
  }
  onNoClick(code): void {
    this.dialogRef.close(code);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
