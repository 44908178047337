<div class="container-fluid mainWrapper">
    <div class="row h-100">
        <div class="col-12 col-lg-8 leftWrapper h-100 overflow-auto">
            <div class="d-block d-lg-none w-100 loginBar text-center my-3">
                <span (click)="openRegistrationDialog()" class="registrationLink"><b>Registracija/Login</b></span>
                <img class="ml-1" width="32px" src="../assets/images/register_icon.png" />
            </div>
            <div class="row sticky-top headerTop bg-white align-items-center" clickOutside (clickOutside)="closeNavbar()">
                <div (click)="handleNavbar()" class="col-12 text-right d-block d-lg-none py-3 pl-0">
                    <span>Izaberi fakultet</span>
                    <img src="../assets/images/navArrow.png" class="ml-2" height="17px" />
                </div>
                <div class="col-3 d-none d-lg-flex flex-row align-items-center px-0">
                    <img src="../assets/images/logo krug.png" width="40px" class="ml-1" />
                    <div>
                        <span>Powered by</span>
                        <br>
                        <span>Binary биоскоп</span>
                    </div>
                </div>
                <div class="col-12 col-lg-9 header d-none" [ngClass]="{ 'd-block': navbarOpen }">
                    <div class="row justify-content-center mb-3">
                        <img style="max-height: 100px;" *ngIf="universities.length == 0 " src="assets/images/loading.svg">
                        <div (click)="selectUniversity(university)" *ngFor="let university of universities" class="col-12 col-lg-auto text-center">
                            <!-- <div class="selectedUnderlineWrapper"> -->
                            <span class="headerNav university expandButtonInner"> {{ university.name }}</span>
                            <!-- <div *ngIf="university.selected" class="selectedUnderline"></div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="row justify-content-center mb-3">
                        <div (click)="selectFaculty(selectedUniversity, faculty)" *ngFor="let faculty of selectedUniversity.faculties" class="col-12 col-lg-auto text-center">
                            <div class="selectedUnderlineWrapper">
                                <span class="headerNav"> {{ faculty.name }}</span>
                                <div *ngIf="faculty.selected" class="selectedUnderline"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5 mt-lg-0">
                <div class="col-12 col-lg-8 d-flex justify-content-center align-items-center">
                    <span class="headerNav university expandButtonInner m-1" (click)="currentAbout = 'aboutService'"> O Pripremi</span>
                    <span class="headerNav university expandButtonInner m-1" (click)="currentAbout = 'aboutUs'"> O Nama</span>
                </div>
            </div>
            <div class="row" *ngIf="currentAbout == 'aboutUs'">
                <div class="col-12 col-lg-8 d-flex justify-content-center align-items-center flex-column ">
                    <h4 class="text-left w-100">Ko smo mi</h4>
                    <p>
                        Idejni tvorac i pokretač mata.rs je <b>Bojan Martinović</b>. Završio je Računarsku gimnaziju u Beogradu, a zatim na Fakultetu Organizacionih Nauka diplomirao 2017. godine kao student generacije sa prosečnom ocenom 10.0. Bojan je
                        još tokom studija, kroz privatne časove koje je držao svojim kolegama, spajao svoju ljubav prema IT-u i prenošenju znanja.
                    </p>
                    <p>
                        Po završetku osnovnih studija na FON-u, opredeljuje se za master program na univerzitetu „Politecnico di Milano”. Ne želevši da odustane od svoje ljubavi prema edukaciji mladih, 2018. godine prelazi sa forme privatnih časova na podučavanje kroz video
                        materijale, te tako osniva <b>Binary Bioskop</b>, hab sa 32 računara za interaktinvno učenje u blizini FON-a, koji je od osnivanja imao više od 15000 poseta.
                    </p>
                    <p>
                        Bojan je predavač na 12 kurseva u okviru Binary Bioskop-a, a kako bi se obuhvatili kursevi za što veći broj ispita na FON-u, ostvarena je saradnja sa još 8 profesora različitih oblasti, pa hab sada broji preko 30 kurseva ukupno. Binary Bioskop je za relativno
                        kratko vreme svog postojanja prepoznat među studentima kao najkvalitetniji i najpraktičniji vid priprema za ispite. Stekao je poverenje više od 1200 studenta koji mu se, nakon uspešnih rezultata na ispitima, rado vraćaju kao sigurnom
                        pomoćniku za pripremu predstojećih ispita.
                    </p>
                    <p>
                        Veliki doprinos u formiranju brenda ima i <b>Katarina Belojica</b>, takođe bivša studentkinja FON-a, koja se već niz godina uspešno bavi grafičkim dizajnom i brendingom i koja je velikim delom zaslužna za to da naš hab postane
                        omiljen među studentima.
                    </p>
                    <p>
                        Od marta 2020. godine, kao odgovor na pandemiju, a u cilju pružanja mogućnosti studentima da i dalje prate naše pripreme na bezbedan način, razvijena je online platforma Binary Bioskopa, koja omogućava interaktivan i neograničen pristup video materijalima.
                        Time je obezbeđeno da studenti mogu da prate pripreme u udobnosti svoga doma, u vreme koje njima najviše odgovara, bez dodatnog gubljenja vremena koje iziskuje putovanje do haba i potrebe za rezervacijom mesta.
                    </p>
                    <h4 class="text-left w-100">Kako je nastala mata.rs</h4>
                    <p>
                        Odluka o izboru fakulteta, a potom i priprema za isti, jako je važan period koji neretko nosi veliku neizvesnost pa i stres kod mladih ljudi. Budući brucoši trenutno se nalaze u specifičnoj situaciji izazvanoj pandemijom Covid-19, pa bi svaki odlazak
                        na individualne ili grupne pripreme za prijemni nosio dodatnu brigu za zdravlje učenika.
                    </p>
                    <p>
                        Iz tih razloga, kao i iz želje da im ceo proces učinimo što je moguće lagodnijim i jednostavnijim, a kao prirodan korak nakon uspešnosti Binary Bioskop online kurseva, nastala je i <b>mata.rs</b>, e-learning servis za <b>pripreme
                        za prijemni iz matematike</b>. Korisnici platforme su u mogućnosti da prate pripeme bilo gde i u njima najpovoljnije vreme, pa tako štede svoje vreme i izbegavaju mogućnost zaraze.
                    </p>
                    <p>
                        Naša misija je obezbediti sveobuhvatno gradivo za pripremu prijemnog iz matematike na jednom mestu, na kvalitetan i povoljan način.
                    </p>
                    <p>
                        Pripreme obuhvataju celokupno gradivo za polaganje matematike na prijemnim ispitima Univerziteta u Beogradu i Univerziteta u Nišu, i to sledećih fakulteta:

                    </p>
                    <ul class="w-100 pl-3" style="list-style-type: circle;font-size: 14px;">
                        <li style="list-style-type: circle;">
                            <b>Fakultet organizacionih nauka</b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Ekonomski fakultet u Beogradu</b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Građevinski fakultet u Beogradu
                            </b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Matematički fakultet u Beogradu

                            </b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Rudarsko-geološki fakultet u Beogradu</b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Saobraćajni fakultet u Beogradu
                            </b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Tehnološko-metalurški fakultet u Beogradu
                            </b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Ekonomski fakultet u Nišu</b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Matematički fakultet u Nišu</b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Elektronski fakultet u Nišu</b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Prirodno-matematički fakultet u Nišu</b>
                        </li>
                        <li style="list-style-type: circle;">
                            <b>Tehnološki fakultet u Leskovcu</b>
                        </li>
                    </ul>
                    <p>
                        Predavač na pripremama je <b>Daliborka Vujić</b>, profesor Četrnaeste beogradske gimnazije, sa višegodišnjim iskustvom u obrazovanju srednjoškolaca, kao i u samoj pripremi za prjemni ispit iz matematike. Profesorka svojom pristupačnom
                        i postupnom metodom prenošenja znanja, približava učenicima i najkompleksnije matematičke oblasti i koncepte, čineći ih tako razumljivim i jasnim.
                    </p>
                    <p>
                        Profesorka ostvaruje prijatnu i neposrednu komunikaciju sa učenicima, pa je na raspolaganju za sva eventualna pitanja i nedoumice koje učenici mogu imati tokom pripreme. Napredak učenika biće propraćen tokom cele pripreme kroz zadatke za samostalni rad
                        i probne prijemne ispite koji će biti organizovani, a sve u cilju ostvarenja punog potencijala i najboljih mogućih rezultata svakog učenika. </p>
                </div>
            </div>

            <div class="row" *ngIf="currentAbout == 'aboutService'">
                <div class="col-12 col-lg-8 paragraph position-relative scrollshadow" [ngClass]="{ 'h-100': expanded }">
                    <h4>Priprema za prijemni ispit</h4>
                    <p>
                        Edukativni centar Binary Bioskop organizuje online pripreme za prijemni ispit iz matematke za ukupno 7 fakulteta Univerziteta u Beogradu. Kako biste dobili sadržaj za željeni fakultet, kliknite na odgovarajuću karticu na vrhu ove stranice.
                    </p>
                    <p>
                        Celokupnu pripremu je, putem naše e-learning platforme, moguće pohađati od kuće, a u cilju praćenja napretka svih polaznika, koristićemo i kanale komunikacije kao što su email i WhatsApp. Osim neograničenog pristupa svim časovima 24/7, za sve učenike
                        biće organizovani i probni prijemni ispiti - simulacija iskustva koje sve buduće brucoše očekuje na polaganju. Nakon svake simulacije, svi polaznici će dobiti svoj broj poena kako bi mogli da se orijentišu o trenutnom nivou pripreme.
                        Naš tim će biti na raspolaganju svim upisanim učenicima tokom cele pripreme.
                    </p>
                    <p>
                        Ovde možete pronaći listu video snimaka koja će vam pomoći da savladate oblast polinoma, a ujedno i da se upoznate sa načinom rada. Ukoliko želite da rezervišete celu pripremu, možete svoje podatke ostaviti klikom na dugme "Kupi pripremu", nakon čega
                        ćemo vas kontaktirati radi daljih instrukcija. U tabeli ispod se nalazi cenovnik, a za grupu učenika koji se zajedno prijave, nudimo mogućnost da imaju zajedničku WhatsApp grupu za komunikaciju sa nama, kao i popuste na cenu pripreme.
                    </p>
                </div>
                <div class="expandButtonWrapper">
                    <div (click)="expandParagraph()" class="col-12 col-lg-8 py-1 mt-3 text-center">
                        <div class="expandButtonInner py-2 px-2">
                            <span *ngIf="!expanded">pročitaj više</span>
                            <span *ngIf="expanded">pročitaj manje</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8 paragraph">
                    <h4>Cenovnik</h4>
                    <app-pricing-table></app-pricing-table>
                </div>
                <div class="col-12 col-lg-8 paragraph">
                    <h4>Šta je uključeno u cenu cele pripreme?</h4>
                    <app-whats-included [website]=website [selectedFaculty]=selectedFaculty></app-whats-included>
                </div>
                <div class="col-12 col-lg-8 paragraph">
                    <h4>Šta je uključeno u cenu paketa probnih prijemnih?</h4>
                    <app-whats-included-trial></app-whats-included-trial>
                </div>
            </div>

        </div>
        <div class="col-12 col-lg-4 pt-4 rightFade h-100 text-center d-flex align-items-center flex-column">
            <div class="d-none d-lg-flex justify-content-between align-items-center flex-row w-100">
                <div class="">
                    <span>kontakt telefon</span>
                    <a href="tel:+066001100" class="footerPhone">
                        <img height="10px" src="../assets/images/phone.png" />
                        <span>+38166001100</span>
                    </a>
                </div>
                <div class="w-50">
                    <span (click)="openRegistrationDialog()" class="registrationLink"><b>Registracija/Login</b></span>
                    <img class="ml-1" width="32px" src="../assets/images/register_icon.png" />
                </div>
            </div>
            <img *ngIf="selectedFaculty.groups==undefined" src="assets/images/loading.svg">
            <app-clips *ngIf="selectedFaculty.groups!=undefined" class=" w-100 d-flex flex-column align-items-center" [groups]=selectedFaculty.groups [selectedUniversity]=selectedUniversity [universities]=universities [selectedFaculty]=selectedFaculty [faculties]=selectedUniversity.faculties></app-clips>

            <div class="d-lg-flex brand mr-3 mb-3 text-left">

            </div>
        </div>
    </div>
    <app-footer></app-footer>
    <div class=" row d-flex d-lg-none w-100 blankRow "></div>
    <div (click)="scrollTo() " class="row d-flex d-lg-none bottomToolbar w-100 ">
        <div class="col-12 text-center text-white d-flex justify-content-center align-items-center ">
            <span><img class="pr-1 " width="20px " src="../assets/images/play.png " />gledaj prvu oblast besplatno</span>
        </div>
    </div>
    <img class="person d-none d-lg-block " src="../assets/images/person.png " />
</div>