<div class="container-fluid text-center">
    <h1>LOGIN</h1>
    <form class="w-100">
      <div class="col-12">
        <mat-form-field  class="w-100 mt-3">
          <mat-label>Email</mat-label>
          <input matInput #email placeholder="Email" required />
        </mat-form-field>
        <mat-form-field class="w-100 mb-3">
          <mat-label>Password</mat-label>
          <input
            matInput
            #password
            type="password"
            placeholder="Lozinka"
            required
          />
        </mat-form-field>
      </div>
      <div>
        <button
          class="w-100 mb-3"
          style="background-color: #ffbdcf;border-radius: 25px"
          (click)="login(email.value, password.value)"
          mat-button
        >
          Uloguj se
        </button>
      </div>
      <i class="text-dark" style="font-size:1rem"
        >Zaboravljena lozinka?<span
          (click)="openPasswordResetDialog()"
          style="color:#ffbdcf;cursor: pointer;"
          ><u>Resetuj je</u></span
        ></i
      >
    </form>
  </div>
  