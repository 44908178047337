import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tos-dialog',
  templateUrl: './tos-dialog.component.html',
  styleUrls: ['./tos-dialog.component.scss']
})
export class TosDialogComponent implements OnInit {

  checkedBoxes={
    theft:false,
    terms:false
  }

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TosDialogComponent>,) { }

  ngOnInit(): void {
  }
  checked(checkbox, name){
    this.checkedBoxes[name] = checkbox.checked;
    console.log(this.checkedBoxes)
    if(this.checkedBoxes.terms && this.checkedBoxes.theft)
      this.dialogRef.close(200)
  }
  

}
