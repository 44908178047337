<div class="container-fluid text-center">
    <h1>Reset lozinke</h1>
    <form class="w-100">
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100 mt-3">
          <mat-label>Email</mat-label>
          <input #email matInput placeholder="" required />
        </mat-form-field>
      </div>
      <div>
        <button
          class="w-100 mb-3"
          style="background-color: #ffbdcf;border-radius: 25px"
          (click)="resetPassword(email.value)"
          mat-button
        >
          Resetuj
        </button>
      </div>
    </form>
  </div>
  