<div class="pricingTable">
    <div class="h-33 d-flex bb">
        <div class="w-33 h-100 pricingTableCell d-flex pl-3 pb-1 text-left align-items-center"></div>
        <div class="w-33 h-100 pricingTableCell bl d-flex justify-content-center align-items-center">
            <span>Cela priprema</span>
        </div>
        <div class="w-33 h-100 text-center pricingTableCell bl d-flex justify-content-center align-items-center">
            <span>Paket probnih prjemnih</span>
        </div>
    </div>
    <div class="h-33 d-flex bb">
        <div class="w-33 h-100 pricingTableCell d-flex pl-3 pb-1 text-left align-items-center">
            Individualna<br /> prijava
        </div>
        <div class="w-33 h-100 pricingTableCell bl d-flex justify-content-center align-items-center">
            <span style="font-size: 22px;">24000 rsd</span>
        </div>
        <div class="w-33 h-100 pricingTableCell bl d-flex justify-content-center align-items-center">
            <span style="font-size: 22px;">3800 rsd</span>
        </div>
    </div>
    <div class="h-33 d-flex bb">
        <div class="w-33 h-100 pricingTableCell d-flex pl-3 pb-1 align-items-center">
            <span>Grupna <br />prijava</span>
        </div>
        <div class="w-33 h-100 pricingTableCell d-flex">
            <div class="w-50 bl h-100 text-center d-flex flex-column justify-content-center">
                <span class="fs-08">3+ osoba</span>
                <span class="fs-1">21000 rsd</span>
            </div>
            <div class="w-50 h-100 bl text-center d-flex flex-column justify-content-center">
                <span class="fs-08">5+ osoba</span>
                <span class="fs-1">19000 rsd</span>
            </div>
        </div>
        <div class="w-33 h-100 pricingTableCell d-flex">
            <div class="w-50 bl h-100 text-center d-flex flex-column justify-content-center">
                <span class="fs-08">3+ osoba</span>
                <span class="fs-1">3300 rsd</span>
            </div>
            <div class="w-50 h-100 bl text-center d-flex flex-column justify-content-center">
                <span class="fs-08">5+ osoba</span>
                <span class="fs-1">2800 rsd</span>
            </div>
        </div>
    </div>
    <!-- <div class="h-25 d-flex boldContent">
        <div class="w-33 h-100 pricingTableCell d-flex pl-3 pb-1 text-left align-items-center">
            Black Friday ponuda <br> za kupovine do kraja novembra 2021.
        </div>
        <div class="w-33 h-100 pricingTableCell bl d-flex justify-content-center align-items-center">
            <span style="font-size: 22px;">14999 rsd</span>
        </div>
        <div class="w-33 h-100 pricingTableCell bl d-flex justify-content-center align-items-center">
            <span style="font-size: 22px;">1999 rsd</span>
        </div>
    </div> -->
</div>