import { Component, OnInit, InjectionToken, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { AngularFireFunctions } from '@angular/fire/functions'
@Component({
  selector: 'app-buy-dialog',
  templateUrl: './buy-dialog.component.html',
  styleUrls: ['./buy-dialog.component.scss']
})
export class BuyDialogComponent implements OnInit {
  selectedFaculty;
  selectedUniversity;
  individualPrice = 24000;
  groupPrice = 21000;
  applicationType: string = 'individual';
  fullnes: string = 'full';
  numOfMembers: number = 1;
  hasPromoCode = false;
  showLoading = false;
  showThankYou = false;
  members = [{
    index: 0
  }];
  promoCode;
  constructor(
    public zone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private func: AngularFireFunctions,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    console.log(data)
    this.selectedFaculty = data.selectedFaculty;
    this.selectedUniversity = data.selectedUniversity;
    console.log(this.selectedUniversity);
  }
  applicationTypeChange(change: MatRadioChange) {
    this.applicationType = change.value
    if (change.value === "individual") this.members = [{
      index: 0
    }]
    else
      this.members = [{
        index: 0
      }, {
        index: 1
      }, {
        index: 2
      }];
    this.numOfMembers = this.members.length
    this.numOfMembersChange(this.numOfMembers)
  }
  incrementNumOfMembers() {
    this.members.push({
      index: this.members.length
    })
    this.numOfMembers = this.members.length;
    this.numOfMembersChange(this.numOfMembers)
  }
  decrementNumOfMembers() {
    if (this.members.length < 4) throw new Error("Has to be atleast 3 members in a group")
    this.members.pop()
    this.numOfMembers = this.members.length;
    this.numOfMembersChange(this.numOfMembers)
  }
  numOfMembersChange(numOfMembers) {
    if (numOfMembers >= 3) {
      this.groupPrice = (numOfMembers >= 5) ? (this.fullnes == 'full') ? 19000 : 2800 :(this.fullnes == 'full') ? 21000:3300;
      this.members = [];
      this.numOfMembers = numOfMembers;
      for (let i = 0; i < numOfMembers; i++) {
        this.members.push({
          index: i
        })
      }
      console.log(this.members)
    }
  }
  nameChange(change, member) {
    member.name = change.target.value;
    console.log(member)
  }
  fullnesChange(change: MatRadioChange) {
    this.fullnes = change.value;
    this.numOfMembersChange(this.numOfMembers)
  }
  emailChange(change, member) {
    member.email = change.target.value;
    console.log(member)
  }
  phoneChange(change, member) {
    member.phone = change.target.value;
    console.log(member)
  }
  hasPromoCodeChange() {
    this.hasPromoCode = !this.hasPromoCode;
  }
  promoCodeChange(promoCode) {
    this.promoCode = promoCode
  }
  sendApplication() {
    this.showLoading = true;
    this.func.httpsCallable('sendApplication')({
      faculty: this.selectedFaculty,
      university: this.selectedUniversity,
      numOfMembers: this.members.length,
      promoCode: this.promoCode,
      members: this.members,
      fullnes: this.fullnes
    }).subscribe(data => {
      this.zone.run(() => {
        this.showLoading = false;
        this.showThankYou = true;
        console.log(data, this.showLoading)
        this.changeDetectorRef.markForCheck();
      });
    }, (err => {
      console.log(err)
    }))
    console.log(this.members, this.promoCode, this.selectedFaculty)
  }
  ngOnInit(): void {
  }

}
