<div #videoContainer class="iframeWrapper justify-content-start flex-column d-flex align-items-center">
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel *ngFor="let group of groups" [expanded]="group.open">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{group.name}}
                </mat-panel-title>
                <!-- <mat-panel-description>
                    {{group.clips.length}} video snimaka
                </mat-panel-description> -->
            </mat-expansion-panel-header>
            <div class="justify-content-start flex-column d-flex align-items-center">
                <ng-container *ngFor="let clip of group.clips; let i = index">
                    <iframe *ngIf="clip.url" id="video{{ i }}" class="mb-3 paidClip" [src]="clip.url | safe: 'resourceUrl'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div *ngIf="!clip.url" class="paidClip mb-3">
                        <h3 class="m-0">{{clip.clipName}}</h3>
                    </div>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div class="buy d-flex justify-content-center align-items-center" (click)="openBuyDialog()">
    <span>
                KUPI
                <br />
                PRIPREMU
              </span>
</div>