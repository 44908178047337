import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { BuyDialogComponent } from '../buy-dialog/buy-dialog.component';
import { RegisterDialogComponent } from '../register-dialog/register-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-clips',
  templateUrl: './clips.component.html',
  styleUrls: ['./clips.component.scss']
})
export class ClipsComponent implements OnInit {
  @ViewChild('videoContainer') videoContainer: ElementRef;
  @Input('selectedFaculty') selectedFaculty;
  @Input('selectedUniversity') selectedUniversity;
  @Input('faculties') faculties;
  @Input('universities') universities;
  @Input('groups') groups;

  freeVideos = [
    "https://youtube.com/embed/yKSV0r1mNYI",
    "https://youtube.com/embed/1CBCjGYlBJI",
    "https://youtube.com/embed/Zy6iGVHvRis",
    "https://youtube.com/embed/K7MsUQu6NNo",
    "https://youtube.com/embed/uzwmXrOOho0"
  ]
  constructor(private domSanitizer: DomSanitizer, private dialog: MatDialog, private _snackBar: MatSnackBar) {
    this.freeVideos.map(unsafeUrl => domSanitizer.bypassSecurityTrustUrl(unsafeUrl));
  }

  ngOnInit(): void {
    this.groups[0].open = true;
    this.groups[1].open = true;
    console.log(this.groups)
    this.groups.forEach(g => {
      g.clips.forEach(c => {
        // c.url = this.domSanitizer.bypassSecurityTrustUrl(c.url)
      })
    })
  }
  async openRegistrationDialog() {
    if (true) {
      var width = '40%';
      // if (window.outerWidth < 992) width = "80%"
      const dialogRef = this.dialog.open(RegisterDialogComponent, {
        width: width,
        height: '90%',
        data: {
        },
        panelClass: 'submitDialogStyle'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 200) this.openSnackBar("Registracija uspešna!", "ok")
        else if (result === 400) this.openSnackBar("Registracija neuspešna. Molimo vas da probate ponovo", "ok")
      });
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  selectFaculty(faculty) {
    this.freeVideos[0] = faculty.videoURL;
  }
  scrollTo() {
    this.videoContainer.nativeElement.scrollIntoView()
  }
  openBuyDialog() {
    // var width = (window.innerWidth < 1025) ? "90%" : "50%";
    const width = "90%";
    this.dialog.open(BuyDialogComponent, {
      height: "90%",
      width: width,
      data: {
        faculties: this.faculties,
        selectedFaculty: this.selectedFaculty,
        selectedUniversity:this.selectedUniversity,
        universities:this.universities
      }
    })
  }

}
