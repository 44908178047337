import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whats-included-trial',
  templateUrl: './whats-included-trial.component.html',
  styleUrls: ['./whats-included-trial.component.scss']
})
export class WhatsIncludedTrialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
